



























import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';

export default defineComponent({
  name: 'uci51LCPrelab4Q2',
  components: {DragToRankInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        dragToRankAnswers: [],
      },
      dragToRankOptions: [
        'Wash the combined organic layers with a sodium bicarbonate solution.',
        'Filter or decant solution to remove the sodium sulfate.',
        'Place the reaction mixture in a separatory funnel and extract with hexanes.',
        'Test the reaction mixture for excess oxidizing agent.',
        'Evaporate to dryness',
        'Quench the excess oxidizing agent with isopropanol if necessary.',
        'Dry the combined organic layers over sodium sulfate.',
        'Wash the combined organic layers with deionized water.',
      ],
    };
  },
});
